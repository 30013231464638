/* import __COLOCATED_TEMPLATE__ from './saved-card-flow.hbs'; */
import { service } from '@ember/service';
import Component from '@glimmer/component';

export default class SiteBillingPaymentModalCheckoutFlowsSavedCardFlow extends Component {
  @service billingModals;
  @service stripeExpressCheckoutElementService;

  get actionsDisabled() {
    return (
      this.billingModals.isPaymentInvalid ||
      this.billingModals.isPersisting ||
      this.stripeExpressCheckoutElementService.isOpen ||
      this.stripeExpressCheckoutElementService.isPersisting
    );
  }

  get cancelDisabled() {
    return this.billingModals.isPersisting || this.stripeExpressCheckoutElementService.isPersisting;
  }
}
