/* import __COLOCATED_TEMPLATE__ from './stripe-express-checkout-element.hbs'; */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import styles from './stripe-express-checkout-element.module.scss';

export default class SiteBillingPaymentModalStripeExpressCheckoutElement extends Component {
  styles = styles;
  @action
  attachExpressCheckoutElement(_element) {
    this.args.stripeExpressCheckoutElement.mount('#express-checkout-element');
  }
}
